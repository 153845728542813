interface HandledError {
  handled: true;
}

export class AppError extends Error implements HandledError {
  public readonly innerError?: unknown;
  public readonly handled = true;
  constructor(
    message: unknown,
    public readonly title?: string
  ) {
    super(typeof message === 'string' ? message : (message as Error).message);

    this.innerError = message instanceof Error ? message : undefined;
  }
}

export class AppNetworkError extends Error implements HandledError {
  public readonly innerError?: unknown;
  public readonly handled = true;
  public readonly type?: string;
  public readonly status?: number;
  constructor(message: unknown, type?: string, status?: number) {
    super(typeof message === 'string' ? message : (message as Error).message);

    this.innerError = message instanceof Error ? message : undefined;
    this.type = type;
    this.status = status;
  }
}

export function isAppError(error: unknown): error is AppError {
  return error instanceof AppError;
}

export function isAppNetworkError(error: unknown): error is AppNetworkError {
  return error instanceof AppNetworkError;
}

export function isHandledError(error: unknown): error is HandledError {
  return (error as HandledError).handled;
}
