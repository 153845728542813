import { createContext, useContext, useState, useEffect } from 'react';
import AWS from 'aws-sdk';

import { useAwsSts } from 'hooks/useAwsSts';

interface AwsS3Context {
  s3: AWS.S3;
  getSignedUrlAsync?: (params: AWS.S3.GetObjectRequest) => Promise<string>;
}

const awsS3Context = createContext<AwsS3Context>({ s3: null });
const { Provider } = awsS3Context;

export function AwsS3Provider(props) {
  const awsS3 = useAwsS3Provider();
  return <Provider value={awsS3}>{props.children}</Provider>;
}

export const useAwsS3 = () => {
  return useContext(awsS3Context);
};

const useAwsS3Provider = () => {
  const { stsCredentials } = useAwsSts();
  const [s3, setS3] = useState<AWS.S3>(null);

  useEffect(() => {
    if (!stsCredentials) return;

    const s3Client = new AWS.S3({
      accessKeyId: stsCredentials?.AccessKeyId,
      secretAccessKey: stsCredentials?.SecretAccessKey,
      sessionToken: stsCredentials?.SessionToken,
      region: 'us-west-1', // potentially make this a variable in the future
    });

    setS3(s3Client);
  }, [stsCredentials]);

  return {
    s3,
    getSignedUrlAsync: async (params: AWS.S3.GetObjectRequest) => {
      return new Promise((resolve: (url: string) => void, reject: (err: Error) => void) => {
        return s3.getSignedUrl('getObject', params, (err, url) => {
          if (err) {
            reject(err);
          }
          resolve(url as string);
        });
      });
    },
  };
};
