import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '../lib/utils';
import Spinner from './spinner';

export const buttonVariants = cva(
  cn(
    'inline-flex items-center justify-center whitespace-nowrap rounded-10 transition-all gap-1', // layout
    'text-sm font-medium', // typography
    'cursor-pointer', // behaviour for components that need to look like buttons
    'border border-transparent', // border
    'ring-offset-background ring-2 ring-transparent', // ring
    'outline-none focus:outline-none focus:ring-offset-2 data-[state="open"]:outline-none  data-[state="open"]:ring-offset-2', // focus
    'disabled:pointer-events-none disabled:bg-weak-50 disabled:text-disabled-300 disabled:border-transparent' // disabled
  ),
  {
    variants: {
      variant: {
        // these should be deprecated because they don't exist in Figma
        default: 'border-transparent text-static-white',
        outline: '',
        subtle: '',
        link: 'underline-offset-4 hover:underline disabled:bg-transparent',

        // from Figma:
        filled: '',
        stroke: '',
        lighter: '',
        ghost: '',
      },
      color: {
        primary:
          'focus:ring-primary-base/25 focus:border-primary-base data-[state="open"]:ring-primary-base/25 data-[state="open"]:border-primary-base',
        success:
          'focus:ring-success-base/25 focus:border-success-base data-[state="open"]:ring-success-base/25 data-[state="open"]:border-success-base',
        neutral:
          'focus:ring-strong-400/25 focus:border-strong-950 data-[state="open"]:ring-strong-400/25 data-[state="open"]:border-strong-950',
        error: 'focus:ring-error-base/25 focus:border-error-base',
      },
      size: {
        default: 'h-10 px-2.5',
        small: 'h-9 rounded-8 px-2',
        'x-small': 'h-8 rounded-8 px-1.5',
        '2x-small': 'h-7 rounded-8 px-1',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
      color: 'primary',
    },
    compoundVariants: [
      {
        variant: 'default',
        color: 'primary',
        className: cn('bg-primary-base hover:bg-primary-darker focus:bg-primary-base'),
      },
      {
        variant: 'outline',
        color: 'primary',
        className: cn('border-primary-base hover:border-transparent', 'hover:bg-primary-base/10', 'text-primary-base'),
      },
      {
        variant: 'subtle',
        color: 'primary',
        className: cn('hover:border-primary-base', 'bg-primary-base/10 hover:bg-transparent', 'text-primary-base'),
      },
      {
        variant: 'ghost',
        color: 'primary',
        className: cn('bg-transparent hover:bg-primary-base/10', 'text-primary-base'),
      },
      {
        variant: 'link',
        color: 'primary',
        className: cn(
          'ring-transparent focus:ring-transparent border-transparent focus:border-transparent',
          'text-primary-base'
        ),
      },

      {
        variant: 'default',
        color: 'success',
        className: cn('bg-success-base hover:bg-success-dark focus:bg-success-base'),
      },
      {
        variant: 'outline',
        color: 'success',
        className: cn('border-success-base hover:border-transparent', 'hover:bg-success-base/10', 'text-success-base'),
      },
      {
        variant: 'subtle',
        color: 'success',
        className: cn('hover:border-success-base', 'bg-success-base/10 hover:bg-transparent', 'text-success-base'),
      },
      {
        variant: 'ghost',
        color: 'success',
        className: cn('bg-transparent hover:bg-success-base/10', 'text-success-base'),
      },
      {
        variant: 'link',
        color: 'success',
        className: cn(
          'ring-transparent focus:ring-transparent border-transparent focus:border-transparent',
          'text-success-base'
        ),
      },
      {
        variant: 'default',
        color: 'neutral',
        className: cn('bg-strong-950 hover:bg-surface-800 focus:bg-strong-950'),
      },
      {
        variant: 'outline',
        color: 'neutral',
        className: cn(
          'border-soft-200 hover:border-transparent',
          'shadow-xs hover:shadow-none focus:shadow-xs',
          'hover:bg-weak-50 focus:bg-background',
          'text-sub-600 hover:text-strong-950 foucs:text-strong-950'
        ),
      },
      {
        variant: 'subtle',
        color: 'neutral',
        className: cn(
          'hover:border-soft-200',
          'hover:shadow-xs focus:shadow-xs',
          'bg-weak-50 hover:bg-background focus:bg-background',
          'text-sub-600 hover:text-strong-950 focus:text-strong-950'
        ),
      },
      {
        variant: 'ghost',
        color: 'neutral',
        className: cn(
          'bg-transparent hover:bg-weak-50',
          'focus:shadow-xs',
          'text-sub-600 hover:text-strong-950 focus:text-strong-950'
        ),
      },
      {
        variant: 'link',
        color: 'neutral',
        className: cn(
          'ring-transparent focus:ring-transparent border-transparent focus:border-transparent',
          'text-sub-600 hover:text-strong-950 focus:text-strong-950'
        ),
      },
      {
        variant: 'default',
        color: 'error',
        className: cn('bg-error-base hover:bg-error-darker focus:bg-error-base'),
      },
      {
        variant: 'outline',
        color: 'error',
        className: cn('border-error-base hover:border-transparent', 'hover:bg-error-base/10', 'text-error-base'),
      },
      {
        variant: 'subtle',
        color: 'error',
        className: cn('hover:border-error-base', 'bg-error-base/10 hover:bg-transparent', 'text-error-base'),
      },
      {
        variant: 'ghost',
        color: 'error',
        className: cn('bg-transparent hover:bg-error-base/10', 'text-error-base'),
      },
      {
        variant: 'link',
        color: 'error',
        className: cn(
          'ring-transparent focus:ring-transparent border-transparent focus:border-transparent',
          'text-error-base'
        ),
      },
      {
        variant: 'stroke',
        color: 'neutral',
        className: cn(
          'bg-white-0 border-soft-200 hover:bg-weak-50 text-sub-600 hover:text-strong-950 hover:border-transparent'
        ),
      },
      {
        variant: 'lighter',
        color: 'neutral',
        className: cn('bg-weak-50 hover:bg-white-0 hover:text-strong-950 hover:border-transparent'),
      },
      {
        variant: 'lighter',
        color: 'primary',
        className: cn('bg-primary-base/10 text-primary-base hover:bg-transparent hover:border-primary-base'),
      },
      {
        variant: 'lighter',
        color: 'success',
        className: cn('bg-success-base/10 text-success-base hover:bg-transparent hover:border-success-base'),
      },
    ],
  }
);

export interface ButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'color'>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  beforeContent?: React.ReactNode;
  afterContent?: React.ReactNode;
  fullWidth?: boolean;
  loading?: boolean;
  loadingText?: string;
  loadingPosition?: 'start' | 'end';
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      color,
      asChild = false,
      beforeContent,
      afterContent,
      children,
      fullWidth,
      loadingPosition = 'start',
      loadingText,
      loading,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';

    if (loading) {
      if (loadingPosition === 'end') {
        afterContent = <Spinner className="h-5 w-5" />;
      } else {
        beforeContent = <Spinner className="h-5 w-5" />;
      }

      children = loadingText;
    }

    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size, color, className }),
          fullWidth && 'w-full',
          loading && 'pointer-events-none'
        )}
        ref={ref}
        {...props}
      >
        {!!beforeContent && <span className="h-5 w-5 [&>svg]:h-5 [&>svg]:w-5">{beforeContent}</span>}
        {!!children && <span className="p-1">{children}</span>}
        {!!afterContent && <span className="h-5 w-5 [&>svg]:h-5 [&>svg]:w-5">{afterContent}</span>}
      </Comp>
    );
  }
);

Button.displayName = 'Button';

export default Button;
