import { createIcon } from './utils';

const CheckboxCircleFill = createIcon(
  'CheckboxCircleFill',
  <>
    <path
      d="M12.5 21C7.5293 21 3.5 16.9707 3.5 12C3.5 7.0293 7.5293 3 12.5 3C17.4707 3 21.5 7.0293 21.5 12C21.5 16.9707 17.4707 21 12.5 21Z"
      fill="currentColor"
    />
    <path
      d="M11.6027 15.6L17.9657 9.2361L16.6931 7.9635L11.6027 13.0548L9.0566 10.5087L7.784 11.7813L11.6027 15.6Z"
      fill="white"
    />
  </>
);

export default CheckboxCircleFill;
