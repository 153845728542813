import { HammrUser } from 'interfaces/user';
import { handleNetworkError, logError, validateNetworkResponse } from 'utils/errorHandling';
import { apiRequest, defaultHammrHeaders } from 'utils/requestHelpers';

/**
 * @deprecated use usersService.get()
 * @param uid
 */
export const getHammrUser = async (uid: string) => {
  try {
    // parse uid to be integer - what backend expects
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v1/users/${uid}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        ...defaultHammrHeaders,
      },
    });
    await validateNetworkResponse(res);
    const json = await res.json();
    return json.data.user;
  } catch (err) {
    console.log(`Error fetching user: ${err}`);
    logError(err);
    handleNetworkError(err);
  }
};

/**
 * @deprecated use userService.get
 */
export const getHammrUsers = async (urlParamsObj): Promise<{ users: HammrUser[] }> => {
  const transformedUrlParams = new URLSearchParams(urlParamsObj).toString();
  try {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v1/users?${transformedUrlParams}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        ...defaultHammrHeaders,
      },
    });

    await validateNetworkResponse(res);

    const json = await res.json();
    return json.data;
  } catch (err) {
    console.log(`Error fetching users: ${err}`);
    logError(err);
    handleNetworkError(err);
  }
};

export const updateHammrUser = async (id, data) => {
  try {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v1/users/${id}`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        ...defaultHammrHeaders,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const json = await res.json();

    const jsonError: string & Error & { message: string; type?: string } = json.error;

    if (jsonError) {
      if (jsonError.type && jsonError.message) {
        throw jsonError;
      } else {
        throw new Error(jsonError);
      }
    }

    return json.data;
  } catch (err) {
    console.log(`Error updating user: ${err}`);
    logError(err);
    throw err;
    // handleNetworkError(err);
  }
};

export const createHammrUser = async (data) => {
  try {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v1/users`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        ...defaultHammrHeaders,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const json = await res.json();

    if (json.error) {
      throw json.error;
    }

    return json.data;
  } catch (err) {
    console.log(`Error creating user: ${err}`);
    logError(err);
    throw err;
    // handleNetworkError(err);
  }
};

export const addHammrUserToPayroll = async (uid: number, data) => {
  try {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v1/users/${uid}/add-to-payroll`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        ...defaultHammrHeaders,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const json = await res.json();

    if (json.error) {
      throw json.error;
    }

    return json.data;
  } catch (err) {
    console.log(`Error creating user: ${err}`);
    logError(err);
    throw err;
    // handleNetworkError(err);
  }
};

export const updateUserPayrollItems = async (data) => {
  try {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v1/users-payroll-items`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        ...defaultHammrHeaders,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    await validateNetworkResponse(res);
    const json = await res.json();
    return json.data;
  } catch (err) {
    console.log(`Error updating user: ${err}`);
    logError(err);
    handleNetworkError(err);
  }
};

export const getUserBenefits = async (id: number) => {
  return apiRequest(`users/${id}/employee-benefits`);
};

export const userService = {
  list: async <T = HammrUser>(urlParams: Record<string, string | number | boolean>): Promise<T[]> =>
    await apiRequest<{ users: T[] }>(`users`, {
      method: 'GET',
      urlParams,
    }).then((response) => response.users),

  get: async (id: number): Promise<HammrUser> =>
    await apiRequest(`users/${id}`, {
      method: 'GET',
    }).then((response) => response.user),
};
