import { useState, useEffect, useContext, createContext, ReactNode } from 'react';
import { useRouter } from 'next/router';
import { useAuth } from './useAuth';
import { getHammrOrganization, updateHammrOrganization, getCheckCompany } from 'services/company';
import { transformOrganization } from 'utils/dataTransformers';
import { Company, CheckCompany } from 'interfaces/company';
import { logError } from 'utils/errorHandling';
import { AppError } from 'utils/AppError';

export interface AuthContextState {
  company: Company | null;
  checkCompany: Partial<CheckCompany>;
  getCompany: (id: number) => Promise<void | { error: unknown }>;
  updateCompany: (id: number, data: any, skipRefetch?: boolean) => Promise<void | { error: unknown }>;
}

const authContext = createContext<AuthContextState>({
  company: {},
} as AuthContextState);
const { Provider } = authContext;

// CompanyProvider is a Context Provider that wraps our app and makes an company object
// available to any child component that calls the useCompany() hook.
export function CompanyProvider(props: { children: ReactNode }): JSX.Element {
  const company = useCompanyProvider();
  return <Provider value={company}>{props.children}</Provider>;
}

// useCompany is a hook that enables any component to subscribe to company state
export const useCompany = (): AuthContextState => {
  return useContext(authContext);
};

// Provider hook that creates company object and handles state
const useCompanyProvider = (): AuthContextState => {
  const router = useRouter();
  const { user, setUser } = useAuth();
  const [companyId, setCompanyId] = useState(user?.companyId);
  const [company, setCompany] = useState<Company | boolean>(null);
  const [checkCompanyId, setCheckCompanyId] = useState(user?.checkCompanyId);
  const [checkCompany, setCheckCompany] = useState<Partial<CheckCompany>>(null);

  const getCompany = async (id: number) => {
    if (!id) return;
    try {
      const companyData = await getHammrOrganization(id);
      const mappedCompany = transformOrganization(companyData.organization);
      setCompany(mappedCompany);
    } catch (error) {
      logError(error);
      throw new AppError(error);
    }
  };

  const updateCompany = async (id: number, data: any, skipRefetch = false) => {
    try {
      await updateHammrOrganization(id, data);
      if (!skipRefetch) {
        await getCompany(id);
      }
    } catch (error) {
      logError(error);
      throw new AppError(error);
    }
  };

  useEffect(() => {
    setCompanyId(user?.companyId);
  }, [user?.companyId]);

  useEffect(() => {
    // fetch hammr org data to be used in check express
    if (companyId) {
      getHammrOrganization(Number(companyId))
        .then((data) => {
          if (data.err) {
            // throwing makes this hit the catch block and if unauthorized, redirects to /login
            throw new Error(data.err.message);
          }

          const mappedCompany = transformOrganization(data?.organization);
          setCompany(mappedCompany);
        })
        .catch((err) => {
          logError(err);
          if (err.message === 'Unauthorized') {
            setUser(false);
            router.push('/login');
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    setCheckCompanyId(user?.checkCompanyId);
  }, [user?.checkCompanyId]);

  useEffect(() => {
    if (checkCompanyId && user.role === 'ADMIN') {
      getCheckCompany(checkCompanyId)
        .then((res) => {
          setCheckCompany(res);
        })
        .catch((err) => {
          logError(err);
          if (err.message === 'Unauthorized') {
            setUser(false);
            router.push('/login');
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkCompanyId]);

  return {
    company: company as Company,
    checkCompany,
    getCompany,
    updateCompany,
  };
};
