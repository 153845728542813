import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 *
 * @deprecated use `import { cn } from '@/utils/cn';` instead
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
